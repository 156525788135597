import { sendToDataLayer } from '@utils';
const LIST_YOUR_CAR_EVENT_CATEGORY = 'content_list_your_car_section';
export enum FormSubmitStatus {
  Success = 'success',
  Fail = 'not_success'
}
export const getFormFieldError = (errorObj?: { [key: string]: any }): string => {
  if (!errorObj) return 'undefined';
  const customLabel = Object.keys(errorObj).map((k) => `empty_${k}`);
  return customLabel.join(',');
};
export const trackListYourCarFormSubmit = (status: FormSubmitStatus, errorObj?: { [key: string]: any }) => {
  const customLabel = status === FormSubmitStatus.Success ? 'success' : getFormFieldError(errorObj);
  sendToDataLayer(undefined, {
    event_category: LIST_YOUR_CAR_EVENT_CATEGORY,
    event_action: status,
    event_label: customLabel
  });
};
