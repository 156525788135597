import { FunctionComponent } from 'react';
import './style.scss';

const ThankYou: FunctionComponent = () => {
  return (
    <div className="thankyou-container">
      <i className="icon-ok-circle pb-3" />
      <h2>ข้อมูลของท่านถูกส่งเรียบร้อยแล้ว!</h2>
      <p className="thankyou-container__text">
        เจ้าหน้าที่จะทำการติดต่อกลับภายใน 3-5 วันทำการ
        <br />
        ** ในกรณีที่บริษัท/ร้านของท่าน มีรถเช่ามากกว่า 5 คันขึ้นไป
      </p>
      <div className="thankyou-container__content">
        <p>ท่านสามารถสอบถามข้อมูลเพิ่มเติมเกี่ยวกับการร่วมเป็นพันธมิตรกับเราผ่านทาง</p>
        <p>
          Line@ :{' '}
          <a rel="nofollow" href="https://line.me/R/ti/p/%40drivehub.support">
            @drivehub.support
          </a>
        </p>
        <p>
          หรือ Call Centre โทร.{'  '}
          <a rel="noreferrer" href="tel:+6620385222">
            02-038-5222
          </a>
        </p>
        <div className="contact-img">
          <a rel="nofollow" href="https://line.me/R/ti/p/%40drivehub.support">
            <img src={`/assets/pages/help/line_qr_code.jpg`} alt="qrcode" id="line-qrcode" />
            <img src="/assets/pages/help/line_add_friend.png" alt="line-add" id="line-add" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
