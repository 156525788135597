import { FormikErrors } from 'formik';
import { object, string, InferType } from 'yup';
import Form from 'react-bootstrap/Form';
import province from './province.json';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const listYourCarSchema = () =>
  object()
    .shape({
      city: string().required('pleaseSelect').label('province').defined(),
      company_name: string().required('pleaseProvideBusinessName').label('businessName').defined(),
      address: string().required('pleaseProvideAddress').label('address').defined(),
      fleet_size: string()
        .required('pleaseProvideNumberOfCars')
        .matches(/^\d+$/, 'matchProvideNumbers')
        .label('fleetSize')
        .defined(),
      contact_name: string().required('pleaseProvidePersonName').label('personName').defined(),
      contact_number: string()
        .matches(/^(0)[\d]{9}$/, 'matchPhoneNumber')
        .required('pleaseProvidePhoneNumber')
        .label('phoneNumber')
        .defined(),
      contact_email: string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'matchEmail')
        .required('pleaseProvideEmail')
        .label('email')
        .defined()
    })
    .defined();

export type ListYourCarFormValues = InferType<ReturnType<typeof listYourCarSchema>>;

type ListYourCarFormFieldProps = {
  errors: FormikErrors<ListYourCarFormValues>;
  values: ListYourCarFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  prefix: string;
};
const ListYourCarFormField: FunctionComponent<ListYourCarFormFieldProps> = ({
  prefix,
  values,
  errors,
  handleChange,
  handleBlur
}) => {
  const { t } = useTranslation();

  const options: any = [];
  province[prefix].forEach((el: any) => {
    const obj = {
      value: el,
      label: el
    };
    options.push(obj);
  });

  options.sort(function (a: any, b: any) {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  return (
    <>
      <h2>{t('businessDetails')}</h2>
      <Form.Group className="px-0 px-lg-3 list-your-car-dropdown">
        <Form.Label>{t('province')}</Form.Label>
        <Form.Control
          id="list-your-car-city-input"
          as="select"
          name="city"
          value={values.city}
          onChange={handleChange}
          isInvalid={!!errors.city}
          onBlur={handleBlur}
        >
          <option value="" disabled>
            {t('pleaseSelect')}
          </option>
          {options.map((p: any) => (
            <option key={p.label} value={p.value}>
              {p.label}
            </option>
          ))}
        </Form.Control>
        <i className="icon-down" />
        <Form.Control.Feedback type="invalid">{t(errors.city as string)}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="px-0 px-lg-3">
        <Form.Label>{t('businessName')}</Form.Label>
        <Form.Control
          id="list-your-car-company-name-input"
          type="text"
          placeholder={t('pleaseProvideBusinessName')}
          name="company_name"
          value={values.company_name}
          onChange={handleChange}
          isInvalid={!!errors.company_name}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">{t(errors.company_name as string)}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="px-0 px-lg-3">
        <Form.Label>{t('address')}</Form.Label>
        <Form.Control
          id="list-your-car-address-input"
          as="textarea"
          placeholder={t('pleaseProvideAddress')}
          name="address"
          value={values.address}
          onChange={handleChange}
          isInvalid={!!errors.address}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">{t(errors.address as string)}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="px-0 px-lg-3">
        <Form.Label>{t('fleetSize')}</Form.Label>
        <Form.Control
          id="list-your-car-fleet-size-input"
          type="text"
          placeholder={t('pleaseProvideNumberOfCars')}
          name="fleet_size"
          value={values.fleet_size}
          onChange={handleChange}
          isInvalid={!!errors.fleet_size}
          onBlur={handleBlur}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">{t(errors.fleet_size as string)}</Form.Control.Feedback>
      </Form.Group>
      {/* ------------ CONTACT FORM ------------*/}
      <h2>{t('contact')}</h2>
      <Form.Group className="px-0 px-lg-3">
        <Form.Label>{t('personName')}</Form.Label>
        <Form.Control
          id="list-your-car-contact-namee-input"
          type="text"
          placeholder={t('pleaseProvidePersonName')}
          name="contact_name"
          value={values.contact_name}
          onChange={handleChange}
          isInvalid={!!errors.contact_name}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">{t(errors.contact_name as string)}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="px-0 px-lg-3">
        <Form.Label>{t('phoneNumber')}</Form.Label>
        <Form.Control
          id="list-your-car-contact-number-input"
          type="text"
          placeholder={t('pleaseProvidePhoneNumber')}
          name="contact_number"
          value={values.contact_number}
          onChange={handleChange}
          isInvalid={!!errors.contact_number}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">{t(errors.contact_number as string)}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="px-0 px-lg-3">
        <Form.Label>{t('email')}</Form.Label>
        <Form.Control
          id="list-your-car-contact-email-input"
          type="อีเมล"
          placeholder={t('pleaseProvideEmail')}
          name="contact_email"
          value={values.contact_email}
          onChange={handleChange}
          isInvalid={!!errors.contact_email}
          onBlur={handleBlur}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <Form.Control.Feedback type="invalid">{t(errors.contact_email as string)}</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default ListYourCarFormField;
