import { FunctionComponent, useEffect, useState } from 'react';
import { withTrans } from '@shares/locales/hoc';
import { DefaultProps } from '../interface';
import { withFormik, FormikProps } from 'formik';
import ListYourCarFormField, {
  ListYourCarFormValues,
  listYourCarSchema
} from '@pages/help/components/list-your-car-form-field';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { API_LEGACY_LINK } from '@config';
import ThankYou from '@pages/help/components/thankyou';
import { trackListYourCarFormSubmit, FormSubmitStatus } from './tracking';
import './list-your-car.scss';

type ListYourCarFormProps = DefaultProps & TranslationProps;

const content = {
  th: { pleaseTryAgain: 'โปรดลองใหม่อีกครั้ง', submit: 'ร่วมเป็นพันธมิตรกับเรา' },
  en: { pleaseTryAgain: 'Please try again', submit: 'Submit' }
};
const ListYourCarForm: FunctionComponent<ListYourCarFormProps & FormikProps<ListYourCarFormValues>> = (props) => {
  const isSubmitSuccess = props.status ? (props.status.submitSuccess ? true : false) : false;
  const isSubmitError = props.status ? (props.status.status === 200 ? false : true) : false;
  const [formValid, setFormValid] = useState<boolean>(Object.keys(props.errors).length >= 0);
  const text: { pleaseTryAgain: string; submit: string } = content[props.prefix];

  useEffect(() => {
    Object.keys(props.errors).length <= 0 ? setFormValid(true) : setFormValid(false);
    if (props.isSubmitting && Object.keys(props.errors).length > 0) {
      trackListYourCarFormSubmit(FormSubmitStatus.Fail, props.errors);
      setFormValid(false);
    }
  });
  return (
    <div className="list-your-car">
      {isSubmitSuccess ? (
        <ThankYou />
      ) : (
        <Form onSubmit={(e: any) => props.handleSubmit(e)}>
          <ListYourCarFormField
            errors={props.errors}
            values={props.values}
            handleChange={(e) => {
              props.handleChange(e);
            }}
            handleBlur={() => {
              const valuesObj = Object.keys(props.values).filter((key) => props.values[key] !== '');
              if (valuesObj.length <= 0) return props.setErrors({});
              // props.handleBlur()
            }}
            prefix={props.prefix}
          />
          <div className="mx-0 mx-lg-3">
            <Button
              id="list-your-car-submit"
              type="submit"
              block
              variant={isSubmitError ? 'danger' : 'primary'}
              disabled={props.isSubmitting || isSubmitError || !formValid}
              className={props.isSubmitting ? 'submiting' : ''}
            >
              {props.isSubmitting ? (
                <img src="/assets/shares/loading.svg" style={{ width: 20 }} />
              ) : isSubmitError ? (
                text.pleaseTryAgain
              ) : (
                text.submit
              )}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

const ListYourCar = withFormik<DefaultProps, ListYourCarFormValues>({
  mapPropsToValues: () => ({
    city: '',
    company_name: '',
    address: '',
    fleet_size: '',
    contact_name: '',
    contact_number: '',
    contact_email: ''
  }),
  validationSchema: listYourCarSchema(),

  handleSubmit: (values, { setSubmitting, setStatus }) => {
    const data = {};
    Object.keys(values).map((key) => {
      const objKey = `[partner_requests]${key}`;
      data[objKey] = values[key];
    });
    const param = Object.entries(data)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    axios({
      method: 'post',
      url: `${API_LEGACY_LINK}/partner_requests?${param}`
    })
      .then((result) => {
        if (result.status === 200) {
          setStatus({
            status: result.status,
            submitSuccess: true
          });
          trackListYourCarFormSubmit(FormSubmitStatus.Success);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setStatus({
          status: 404,
          submitSuccess: false
        });
        window.setTimeout(() => {
          setStatus(undefined);
        }, 1000);
        setSubmitting(false);
      });
  }
})(withTrans('help.list_your_car')(ListYourCarForm));
export default ListYourCar;
